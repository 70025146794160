$(document).ready(function() {

    $(".chosen-select").chosen({disable_search_threshold: 5});
    $('.chosen-select-allow-single-deselect').chosen({disable_search_threshold: 5, allow_single_deselect: true});

    // https://eonasdan.github.io/bootstrap-datetimepicker/
    $('.datetimepicker').datetimepicker({
        locale: 'nl'
    });
    $('.datepickeronly').datetimepicker({
        locale: 'nl',
        format: 'DD-MM-YYYY'
    });

    $('.summernote').summernote({
      height: 500,                 // set editor height
      minHeight: null,             // set minimum height of editor
      maxHeight: null,             // set maximum height of editor
    });

    var cache = {};
    $( "#customer-search-field" ).autocomplete({
        minLength: 4,
        source: function( request, response )
        {
            var term = request.term;
            if ( term in cache ) {
              response( cache[ term ] );
              return;
            }

            $.ajax({
                url: base_url+"/crm/customers/search",
                type: "POST",
                dataType: "json",
                data: {
                    '_token': $('meta[name=csrf-token]').attr('content'),
                    search: request.term
                },
                success: function( data ) {
                    var mappedData = $.map( data, function( value, key ) {
                      return {
                            value: key,
                            label: value+' ID: '+key
                          };
                    });
                    cache[ term ] = mappedData;
                    response( mappedData );
                }
            });
        },
        select: function( event, ui )
        {
            //
        },
    });

    /**
    * NAME: Bootstrap 3 Multi-Level by Johne
    * This script will active Triple level multi drop-down menus in Bootstrap 3.*
    */
    $('li.dropdown-submenu').on('click', function(event) {
      event.stopPropagation();
      if ($(this).hasClass('open')){
          $(this).removeClass('open');
      }else{
          $('li.dropdown-submenu').removeClass('open');
          $(this).addClass('open');
     }
    });

});
